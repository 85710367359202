<template>
  <div>
    <div class="row justify-start">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <q-card>
          <q-card-section>
            <q-input v-model="userId" type="text" label="Username" dense>
              <template #after>
                <q-btn color="primary" size="sm" round class="cursor-pointer" icon="search" @click="onSearch" />
              </template>
            </q-input>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <div v-if="!isRoot" class="row justify-start q-mt-lg">
      <div class="col-12">
        <h6 v-if="!profile">
          <span v-if="isSubmitted">Oops, user not found!</span>
        </h6>

        <q-tabs
          v-else
          class="text-teal"
          dense
          :inline-label="platform.mobile"
          outside-arrows
          mobile-arrows
          indicator-color="primary"
        >
          <q-route-tab
            v-for="(t, i) in tabs"
            :key="'tab-' + i"
            :label="t.title"
            :icon="t.icon"
            :to="`/${profile.username + t.to}`"
            exact
          />
        </q-tabs>

        <router-view class="q-mt-lg"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onBeforeMount, onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'

export default {
  name: 'Home',
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const userId = ref('')
    const isRoot = ref(true)
    const $q = useQuasar()

    onBeforeMount(() => {
      if (route.name.toLowerCase() === 'root') {
        location.href = '/'
      } else {
        isRoot.value = false
      }
    })

    onMounted(async () => {
      const id = route.params?.id

      if (id) {
        userId.value = id

        store.dispatch('setProfileFull', { id: userId.value })
      }
    })

    const profile = computed(() => store.state.profile)

    const tabs = ref([{
      title: 'Profile',
      icon: 'manage_accounts',
      to: '/profile'
    }, {
      title: 'Educations',
      icon: 'school',
      to: '/educations'
    }, {
      title: 'Works',
      icon: 'engineering',
      to: '/works'
    }, {
      title: 'Skills',
      icon: 'stars',
      to: '/skills'
    }, {
      title: 'Projects',
      icon: 'auto_stories',
      to: '/projects'
    }, {
      title: 'Certificates',
      icon: 'card_membership',
      to: '/certificates'
    }, {
      title: 'Contacts',
      icon: 'contacts',
      to: '/contacts'
    }])

    const isSubmitted = ref(false)

    const onSearch = async () => {
      if (userId.value) {
        isSubmitted.value = true
        await store.dispatch('setProfileFull', { id: userId.value })
        router.push(`/${userId.value}/profile`)
      }
    }

    return {
      userId,
      isRoot,
      onSearch,
      profile,
      tabs,
      platform: $q.platform.is,
      isSubmitted
    }
  }
}
</script>
